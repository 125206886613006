import React, { useEffect } from 'react'
import HeroSection from '../sections/Hero'
import { detalPageData } from '../sections/Hero/Data'
import DetalProducts from '../sections/DetalProducts'
import Footer from '../components/Footer'
import { Helmet } from 'react-helmet-async'

const DetalPage = () => {


  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }, [])

  return (
    <>
      <Helmet>
        <title>Detal - dulce de leche</title>
        <meta name='description' content="Mleczny karmel w trzech smakach, kajmak klasyczny/kakaowy oraz mleko zagęszczone słodzone" />
      </Helmet>
      <HeroSection {...detalPageData} />
      <DetalProducts />
      <Footer />
    </>
  )
}

export default DetalPage