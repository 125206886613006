import React from 'react'
import { Subtitle } from './SubtitleElements'


const SubtitleComponent = ({text, textBold, textDark}) => {
  return (
    <>
        <Subtitle textDark = {textDark} textBold={textBold}>{text}</Subtitle>
    </>
  )
}

export default SubtitleComponent