import React from 'react'
import { ButtonKontaktSideBar } from '../Button'
import { IoMdClose } from 'react-icons/io'
import { SideBarContainer, SideBarIconContainer, SideBarLink, SideBarMenu, SideBarMenuBtn, SideBarMenuLink, SideBarMenuLinks, SideBarWrapper } from './SideBarElements'


const SideBarComponent = ({ isOpen, toggleSideBar }) => {


    return (
        <SideBarContainer isOpen={isOpen} >
            <SideBarWrapper>

                <SideBarIconContainer>
                    <IoMdClose onClick={toggleSideBar} />
                </SideBarIconContainer>

                <SideBarMenu>
                    <SideBarMenuLinks>
                        <SideBarMenuLink onClick={toggleSideBar} >
                            <SideBarLink to='/'>Strona główna </SideBarLink> </SideBarMenuLink>
                        {/* <SideBarMenuLink onClick={toggleSideBar}>
                            <SideBarLink to='/przemysl'> Przemysl </SideBarLink></SideBarMenuLink> */}
                        <SideBarMenuLink onClick={toggleSideBar}>
                            <SideBarLink to='/detal'>Detal </SideBarLink></SideBarMenuLink>
                        <SideBarMenuLink onClick={toggleSideBar}>
                            <SideBarLink to='/lodziarnie'>Lodziarnie</SideBarLink> </SideBarMenuLink>
                        <SideBarMenuLink onClick={toggleSideBar}>
                            <SideBarLink to='/cukiernie'>Cukiernie </SideBarLink></SideBarMenuLink>
                    </SideBarMenuLinks>

                    <SideBarMenuBtn onClick={toggleSideBar}>
                        <ButtonKontaktSideBar text="Skontaktuj się" />
                    </SideBarMenuBtn>

                    
                </SideBarMenu>

            </SideBarWrapper>
        </SideBarContainer>

    )
}

export default SideBarComponent