import React from 'react'
import { AboutCompanyContainer, AboutCompanyWrapper, Column1, Column2, DulceImage, ParagraphLink, ParagraphA } from './AboutCompanyElements'

import HeaderComponent from '../../components/Header';
import Subtitle from '../../components/Subtitle';



const AboutCompanySection = () => {
  return (
    <>
      <AboutCompanyContainer>
        <AboutCompanyWrapper>
          <Column1>
            <DulceImage src={require('../../images/dulce-image.png')}/>
          </Column1>


          <Column2>
            <HeaderComponent 
              text="O firmie" 
              textDark = "true"
              textBold = "true"
              />
             
            <Subtitle 
              text=" Jako jedyni zajmujemy się dystrybucją pysznego, argentyńskiego przysmaku na obszarze całej Polski."
              textBold = "true"
              textDark = "true"
            />
            

            <ParagraphA>
              Naszym najczęściej sprzedawanym produktem jest baza do lodów naturalnych.
            </ParagraphA>

            <ParagraphA>
              Więcej informacji o naszych produktach mogą państwo znaleźć w zakładach <ParagraphLink to = '/detal'>Detal</ParagraphLink>, <ParagraphLink to = '/detal'>Cukiernie</ParagraphLink> oraz <ParagraphLink to = '/detal'>Lodziarnie</ParagraphLink>.
            </ParagraphA>

          </Column2>


        </AboutCompanyWrapper>
      </AboutCompanyContainer>

    </>
  )
}

export default AboutCompanySection