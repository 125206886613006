import React from 'react'
import HeaderComponent from '../../components/Header';
import ParagraphComponent from '../../components/Paragraph'
import { Image, ParagraphHelper, SingleItemContainer } from './singleItemElements'


const SingleItem = ({ item }) => {

    const { title, desc, imageUrl } = item;


    return (
        <SingleItemContainer>
            <HeaderComponent
                text={title}
                textBold="true"
                textDark="true"
            />
            <ParagraphHelper>

                <ParagraphComponent
                    text={desc}
                    textBold=""
                    textDark="true"
                />
            </ParagraphHelper>
            <Image src={imageUrl} />
        </SingleItemContainer>
    )
}

export default SingleItem