import styled from 'styled-components'
import { Link as LinkR } from 'react-router-dom'

export const ButtonWrapper = styled(LinkR)`
    font-family: 'Poppins';
    background: rgba(0, 0, 0, 0.5);
    text-decoration: none;
    color: #fff;
    border: 1px solid #fff;
    padding: 13px 20px;
    font-size: 0.875rem;
    transition: 0.3s ease-in-out;
   
    &:hover{
        background: white;
        color: black;
        transition: 0.3s ease-in-out;
    }
    @media screen and (max-width: 768px){
        text-align: center;
    }
`

export const ButtonSideBarContainer = styled(LinkR)`
    color: #F28E1D;
    text-decoration: none;
    border: 1px solid #F28E1D;
    padding: 15px 50px;
    font-family: 'Poppins';

`