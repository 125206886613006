import styled from 'styled-components'
import { Link as LinkR } from 'react-router-dom'

export const SideBarContainer = styled.div`
    height: 100%;
    width: 100%;
    background: #070707;
    position: fixed;
    opacity: ${({ isOpen }) => (isOpen ? '100%' : '0%')};
    top: ${({ isOpen }) => (isOpen ? '0' : '-100%')};
    left: 0;
    right: 0;
    bottom: 0;
    transition: 0.3s ease-in-out;
    z-index: 12;
`

export const SideBarWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    height: 100%;
`

export const SideBarIconContainer = styled.div`
    font-size: 2.5rem;
    position: absolute;
    top: 1rem;
    right: 1rem;
    color: #fff;
    cursor: pointer;
    transition: 0.3s ease-in-out;

    &:hover{
        color: #F28E1D;
        transition: 0.3s ease-in-out;
    }
`
export const SideBarMenu = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`

export const SideBarMenuLinks = styled.ul`
    list-style: none;
    padding: 0;
`
export const SideBarMenuLink = styled.li`
    text-align: center;
    padding: 20px 0px;
    
`

export const SideBarLink = styled(LinkR)`
    text-decoration: none;
    color: #fff;
    font-family: 'Poppins';
    font-size: 1.5rem;

    @media screen and (max-width: 500px){
        font-size: 1rem;
    }


    cursor: pointer;
    transition: 0.3s ease-in-out;

    &:hover{
        color: #F28E1D;
        transition: 0.3s ease-in-out;
    }
`

export const SideBarMenuBtn = styled.div`
    padding-top: 32px;
`












