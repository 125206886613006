import styled from 'styled-components'
import ImageSrc from '../../images/our-products-background-image.png';

export const OurProductContainer = styled.div`

    background: url(${ImageSrc});
    background-size: cover;
    height: 698px;
    position: relative;
`
export const OutProductWrapper = styled.div`
    max-width: 1200px;
    margin: 0 auto;
    position: relative;
    height: 100%;

`
export const Dark = styled.div`
    background: rgba(0,0,0,0.4);
    position: relative;
    height: 100%;
    width: 100%;
`

export const OurProductContent = styled.div`
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    padding: 0px 24px;

    @media screen and (max-width: 768px){
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        padding: 0px 12px;
    }


    
`
export const SubtitleHelper = styled.h2`
`