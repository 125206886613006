import styled from 'styled-components';

export const Container = styled.div`
    padding: 100px 0px;
`

export const Wrapper = styled.div`
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 24px;

    @media screen and (max-width: 950px){
        padding: 0 12px;
    }
`

export const GridContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;


    @media screen and (max-width: 950px){
        display: flex;
        flex-direction: column;
    }

`

export const TableContainer = styled.div`
    table{
        width: 100%;
        border-collapse: collapse;
    }
    font-family: 'Domine';
    font-size: 1rem;
    width: 100%;
    display: block;

    .table-header{
        color: #AEAEAE;

    }
    .table-info{

    }
    .table-orange{
        color: #FFA238;
    }
    .hover{
        border-top: 1px solid #CECECE;
            transition: 0.1s ease-in-out;

            &:hover{
                background: #E5E5E5;
            }
    }
    td{
        padding: 20px 15px;
    }

    
    tr:nth-child(1){
            background: #F3F3F3;
        }
    
    tr:nth-child(3){
            background: #F3F3F3;
        }
    
    tr:nth-child(5){
            background: #F3F3F3;
        }

    @media screen and (max-width: 768px){
        display: none;
    }



`