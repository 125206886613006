import React from 'react'
import { LogoImage, ProductImage, Container, Wrapper, Btn } from './dulceSingleProductElemenets'

const DulceSingleProduct = ({
    id, imgSrc, logoImgSrc, buttonLink}) => {
    return (
        <Container>
            <Wrapper id = {id}>
                <LogoImage src={logoImgSrc}/>
                <ProductImage src = {imgSrc} />
                <Btn to = {buttonLink} >Zobacz więcej</Btn>

            </Wrapper>
            
        </Container>
    )
}

export default DulceSingleProduct