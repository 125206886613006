import styled from 'styled-components';
import { Link as LinkR } from 'react-router-dom';

export const Container = styled.div`

`

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

`
export const LogoImage = styled.img`
    height: 140px;
    padding: 50px 0px;

    @media screen and (max-width: 400px){
        height: 100px;
    }
`

export const ProductImage = styled.img`
    width: 250px;
    padding-bottom: 35px;
    @media screen and (max-width: 400px){
        width: 185px;
    }
`
export const Btn = styled(LinkR)`
    font-family: 'Poppins';
    text-decoration: none;
    color: black;
    border: 1px solid black;
    padding: 15px 45px;
    font-size: 15px;
    transition: 0.3s ease-in-out;

    &:hover{
        background: black;
        color: #fff;
    }
    @media screen and (max-width: 400px){
        padding: 15px 28px;
    }
`