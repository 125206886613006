import styled from 'styled-components';


export const Container = styled.div`

`

export const Wrapper = styled.div`
    max-width: 1200px;
    margin: 0 auto;

    display: flex;
    flex-direction: column;
    align-items: center;

    @media screen and (max-width: 820px){
        margin-top: 25px;
    }
    
`

export const Gap = styled.div`
  &.last-title{
        margin-top: 120px;
        padding: 0px 24px;
        margin-bottom: 100px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        a{
            margin-top: 25px;
            text-decoration: none;
            font-family: 'Poppins';
            font-size: 15px;
            color: black;
            padding: 15px 65px;
            border: 1px solid black;
            transition: 0.3s ease-in-out;
            &:hover{
                background: black;
                color: white;
                transition: 0.3s ease-in-out;
            }
            @media screen and (max-width: 600px){
                padding: 15px 30px;
                font-size: 14px;
            }

        }

        @media screen and (max-width: 600px){
            padding: 50px 12px;
            margin-bottom: 50px;
        }
    }
    padding-top: 100px;
    .auchan-title{
        font-family: "Domine";
        font-size: 25px;
        font-weight: bolder;
        text-align: center;

        @media screen and (max-width: 500px){
        font-size: 22px;
    }
    }
  
    a{
        
    }
    .auchan-subtitle{
        font-size: 16px;
        font-family: "Poppins";
        text-align: center;
        width: 50%;
        line-height: 1.7;

        @media screen and (max-width: 500px){
        font-size: 15px;
        width: 100%;
    }
    }

`

export const GridOneColumn = styled.div`
    width: 40%;
    display: grid;
    grid-template-columns: 1fr;

    @media screen and (max-width: 820px){
        display: flex;
        flex-direction: column;
        gap: 50px;
        width: 100%;
    }
`

export const GridThreeColumns = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;

    @media screen and (max-width: 820px){
        display: flex;
        flex-direction: column;
        gap: 50px;
        width: 100%;
    }
`
export const GridTwoColumns = styled.div`
    width: 70%;
    display: grid;
    grid-template-columns: 1fr 1fr;

    @media screen and (max-width: 820px){
        display: flex;
        flex-direction: column;
        gap: 50px;
        width: 100%;
    }
`