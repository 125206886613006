import React, { useEffect } from 'react'
import HeaderComponent from './../components/Header/index';
import { Container, Wrapper, Component, Flex, Column1, Column2, MapContainer } from '../components/kontaktPage/KontaktPageElements';
import MapComponent from '../components/Map/index'
import Footer from './../components/Footer/index';
import Subtitle from './../components/Subtitle/index';
import ContactFormComponent from '../components/ContactForm';
import { Paragraph } from '../components/Paragraph/ParagraphElements';
import ParagraphComponent from './../components/Paragraph/index';
import { Helmet } from 'react-helmet-async';


const KontaktPage = () => {


  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }, [])

  return (
    <>
      <Helmet>
        <title>Kontakt - dulce de leche</title>
        <meta name='description' content="Wszelkie informacje dotyczące kontaktu z naszą firmą. Zapraszamy do kontaktu!" />
      </Helmet>

      <Container>
        <Wrapper>


          <Flex>
            <Column1>
              <Subtitle
                text="Zapraszamy do kontaktu!"
                textDark="true"
                textBold="true"
              />
              <ParagraphComponent text={"W razie wszelkich pytań prosimy o kontakt telefoniczy lub poprzez wiadomość email na poniższy adres mailowy:"} textBold={false} textDark={true} />

              <a href="mailto:roland@huso.pl" style={{ textDecoration: 'none', color: 'black' }}><i>roland@huso.pl</i></a>

            </Column1>

            <Column2>
              <MapContainer>
                <MapComponent />
              </MapContainer>

            </Column2>
          </Flex>


        </Wrapper>
      </Container >
      <Footer />
    </>


  )
}

export default KontaktPage