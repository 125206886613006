import styled from 'styled-components'



export const HeroSectionContainer = styled.div`
    background-image: ${({ imgSrc }) => `url(${imgSrc})`};
    background-size: cover;
    background-position: center;
    width: 100%;
`

export const HeroSectionWrapper = styled.div`
    max-width: 1150px;
    margin: 0 auto;
    padding: 100px 24px;
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;

    @media screen and (max-width: 768px){
        align-items: center;
        text-align: center;
        padding: 45px 24px 100px 24px;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    & > *{
        margin: 0;
    }

`

export const HeroSectionTitle = styled.h2`

`
export const ParagraphHelper = styled.div`
    max-width: 700px;
    line-height: 2;
`
export const HeroSectionDark = styled.div`
    background-color: rgba(0,0,0,0.4);
`

export const HeroSectionSubtitle = styled.p`

`

export const HeroSubtitle = styled.p`
    font-family: "Poppins";
    color: #fff;
    font-weight: 100;
`