import React from 'react';
import ReactDOM from 'react-dom/client';
import './main.scss';
import App from './App';
import { HelmetProvider } from 'react-helmet-async';
import { StateContext } from './context/StateContext';
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <HelmetProvider>
        <StateContext>
          <App />
        </StateContext>
      </HelmetProvider>
  </React.StrictMode>
);
