
import React, { createContext, useContext, useState } from 'react'
const Context = createContext();
export const useStateContext = () => useContext(Context);

export const StateContext = ({ children }) => {
    const [menu, setMenu] = useState(false);
    const toggleMenu = () => {
        setMenu(!menu);
    }
    return (
        <Context.Provider
            value={{
                menu,
                setMenu,
                toggleMenu,
            }}
        >
            {children}
        </Context.Provider>
    )
}
