import styled from 'styled-components';

export const MainContainer = styled.div`
`

export const MainWrapper = styled.div`

    max-width: 1200px;
    margin: 0 auto;
    margin-bottom: 100px;
    display: flex;
    align-items: center;

    @media screen and (max-width: 800px){
        flex-direction: column;
        margin-bottom: 50px;
    }
`