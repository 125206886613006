import React, { useEffect } from 'react'
import HeroSection from '../sections/Hero'
import { lodziarniePageData } from '../sections/Hero/Data'
import LodziarnieProducts from '../sections/lodziarnieProducts'
import Footer from '../components/Footer'
import { Helmet } from 'react-helmet-async'

const LodziarniePage = () => {

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }, [])


  return (
    <>
      <Helmet>
        <title>Lodziarnie - dulce de leche</title>
        <meta name='description' content="Najlepsze pasty oraz wariegatury dla naszych klientów" />
      </Helmet>
      <HeroSection {...lodziarniePageData} />
      <LodziarnieProducts />
      <Footer />
    </>
  )
}





export default LodziarniePage