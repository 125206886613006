import styled from 'styled-components';

export const CustomersOpinionContainer = styled.div`
`

export const CustomersOpinionWrapper = styled.div`
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 45px 24px;
`

export const OpinionsContainer = styled.div`
   display: flex; 
   gap: 100px;

   @media screen and (max-width: 768px){
       flex-direction: column;
       gap: 0px;
   }
`
export const ParagraphA = styled.p`
    color: #F28E1D;
    line-height: 0;
    font-family: 'Domine';
    font-weight: bolder;
    text-align: right;

`
export const SubtitleHelper = styled.div`
    width: 850px;
    text-align: center;
    padding: 30px 0px 50px 0px;

    @media screen and (max-width: 768px){
        width: auto;
        padding: 10px 0px 20px 0px;
    }
`


export const Column1 = styled.div`
    width: 50%;
    @media screen and (max-width: 768px){
       width: 100%;
       text-align: center;
       border-bottom: 2px solid #D3D3D3;
       padding-bottom: 40px;
   }
`

export const Column2 = styled.div`
    width: 50%;
    @media screen and (max-width: 768px){
       width: 100%;
       text-align: center;
       padding-top: 40px;

   }
`
    

export const EndingContainer = styled.div`
    width: 100%;
    padding: 45px 0px 20px 0;
`
export const EndingWrapper = styled.div`
    float: right;
`