import styled from 'styled-components'


export const CukiernieAboutContainer = styled.div`

    
`
export const CukiernieAboutWrapper = styled.div`
    max-width: 1200px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 100px 24px;

    @media screen and (max-width: 950px){
        display: flex;
        flex-direction: column;
        padding: 50px 12px;
    }

`

export const Image = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`

export const ImageDulce = styled.div`
    
    background-image: ${({ imgSrc }) => `url(${imgSrc})`};
    background-size: cover;
    background-repeat: no-repeat;
    background-position: left;
    height: 600px;
    width: 450px;
    border-radius: 20px;
    transition: 0.3s ease-in-out;

    &:hover{
        transform: scale(1.02);
        box-shadow: 0 0 2em rgba(0,0,0,0.3);
    }

    @media screen and (max-width: 950px){
        display: flex;
        flex-direction: column;
        height: 20rem;
    }
`

export const Content = styled.div`
    padding: 15px 25px;
    @media screen and (max-width: 950px){
        display: flex;
        flex-direction: column;

        & > :first-child{
            text-align: center;
        }
    }
`