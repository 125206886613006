import React, { useEffect } from 'react'
import CukiernieAboutSection from '../sections/CukiernieAbout'
import CukierniePasteleroRaffoleSection from '../sections/CukierniePasteleroRaffole'
import HeroSection from '../sections/Hero'
import { cukierniePageData } from '../sections/Hero/Data'
import Footer from '../components/Footer'
import { Helmet } from 'react-helmet-async'




const CukierniePage = () => {

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }, [])

  return (
    <>
      <Helmet>
        <title>Cukiernie - dulce de leche</title>
        <meta name='description' content="Najważniejsze cechy naszego produktu. Dulce de leche dla cukierni" />
      </Helmet>
      <HeroSection {...cukierniePageData} />
      <CukiernieAboutSection />
      <CukierniePasteleroRaffoleSection />
      <Footer />

    </>
  )
}

export default CukierniePage