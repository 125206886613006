import styled from 'styled-components';
import { Link as LinkR } from 'react-router-dom';

export const AboutCompanyContainer = styled.div`
    background: #fff;
    padding: 75px 0px; 
    width: 100%;
 `

export const AboutCompanyWrapper = styled.div`
    max-width: 1200px;
    margin: 0 auto;
    display: grid;
    padding-right: 24px;
    padding-left: 24px;
    padding-bottom: 75px;
    grid-template-columns: 1fr 1fr;

    @media screen and (max-width: 900px){
       display: flex;
       flex-direction: column;
       text-align: center;
    }
 `

export const Column1 = styled.div`
 `

export const Column2 = styled.div`
   display: flex;
   justify-content: center;
   flex-direction: column;
   align-items: center;
   
 `
export const DulceImage = styled.img`
   height: 500px;
   border-radius: 20px;

   @media screen and (max-width: 600px){
       width: 100%;
       height: auto;
    }

 `

export const ParagraphA = styled.p`
   font-family: 'Poppins';
   font-size: 0.975rem;

    
 `

export const ParagraphLink = styled(LinkR)` 
   text-decoration: underline;
   color: #2D2D2D;
   transition: 0.3s ease-in-out;

   &:hover{
    color: #F28E1D;
    padding: 3px 0px;
    transition: 0.3s ease-in-out;
   } 
   
 `