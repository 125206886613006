import styled from 'styled-components'

export const HeaderContainer = styled.div`
    
`
export const HeaderWrapper = styled.div`
    display: flex;
    @media screen and (max-width: 768px){
      padding: 24px 0px;
    }

`
export const Col1 = styled.div`
    align-self: center;
    margin-top: -10px;
    text-align: center;

`
export const LineSVG = styled.img`
    width: 70%;
`

export const Col2 = styled.h3`
    font-family: 'Domine';
    font-size: 1.4rem;
    padding: 0 20px;
    text-align: center;

    font-weight: ${({ textBold }) => (textBold ? '900' : '100')};
    color: ${({ textDark }) => (textDark ? '#2D2D2D' : '#fff')};

    @media screen and (max-width: 768px){
        font-size: 1.25rem;
        padding: 0 5px;
    }
    
`
export const Col3 = styled.div`
    align-self: center;
    margin-top: -10px;
    text-align: center;
`

