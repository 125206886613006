import styled from 'styled-components';

export const Image = styled.img`
    width: 400px;
    border-radius: 20px;
    margin-top: 50px;

    @media screen and (max-width: 800px){
        width: 300px;
    }

    @media screen and (max-width: 550px){
        width: 240px;
    }
`   

export const SingleItemContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 50px 20px;
    align-items: center;
    text-align: center;
    width: 50%;
    height: 100%;
    transition: 0.3s ease-in-out;
    border-radius: 0px;

    @media screen and (max-width: 800px){
        padding: 30px 0px;
        width: 100%;
    }

    /* &:hover{
        border-radius: 20px;
        box-shadow: 0px 0px 25px 10px rgba(0, 0, 0, 0.1);
    } */
`
export const ParagraphHelper = styled.div`
    height: 80px;
    width: 400px;

    @media screen and (max-width: 950px){
        height: auto;
        width: 400px;
    }

    
    @media screen and (max-width: 450px){
        height: auto;
        width: 200px;
    }
`