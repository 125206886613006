import styled from "styled-components";
import { Link as LinkR } from 'react-router-dom'

export const Nav = styled.nav`
    height: 80px;
    background: rgba(0,0,0,1);
    border-bottom: 1px solid #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    
`
export const Container = styled.div`
`


export const NavBarContainer = styled.div`
    display: flex;
    justify-content: space-between;
    max-width: 1200px;
    padding: 0 24px;
    width: 100%;
    align-items: center;
`

// Dulcedeleche
export const NavBarLogo = styled(LinkR)`
    font-family: 'Domine';
    text-decoration: none;
    color: #fff;
    font-size: 1.2rem;
    transition: 0.3s ease-in-out;

    &:hover{
        color: #F28E1D;
        transition: 0.3s ease-in-out;
    }

`

// Mobile Icon
export const MobileIcon = styled.div`
    display: none;
    cursor: pointer;

@media screen and (max-width: 768px) {
    display: block;
    color: #fff;
    font-size: 25px;
    
}
`

export const NavBarMenu = styled.ul`

    display: block;

    @media screen and (max-width: 768px){
        display: none;
    }

`

export const NavBarItem = styled.li`
list-style: none;
display: inline-block;

&:not(:last-child){
    margin-right: 60px;
}
`
// Przemysl Detal Lodziarnie Cukiernie
export const NavLink = styled(LinkR)`
text-decoration: none;
color: #fff;
font-family: 'Poppins';
font-size: 1rem;
letter-spacing: -1px;
transition: 0.3s ease-in-out;

&:hover{
    color: #F28E1D;
    transition: 0.3s ease-in-out;
}
`

export const NavBarBtn = styled.nav`
  display: block;

@media screen and (max-width: 768px){
    display: none;
}

`
export const NavBarBtnLink = styled(LinkR)`
   font-family: 'Poppins';
   background: rgba(0, 0, 0, 0.5);
   text-decoration: none;
   color: #fff;
   border: 2px solid #fff;
   padding: 10px 20px;
   font-size: 0.875rem;
   transition: 0.3s ease-in-out;
   
   &:hover{
       background: rgba(0, 0, 0, 0.8);
       transition: 0.3s ease-in-out;
   }
`