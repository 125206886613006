import styled from 'styled-components';

export const Container = styled.div`

margin-top: 75px;

form{
        font-family: "Domine";

        .single-row{
            margin: 30px 0px;
        }
        label{
            color: black;
            display: block;
            margin: 0;
            line-height: 1;
            font-size: 13px;
            font-weight: bolder;
            text-align: left;
        }
       
        input{
            font-family: "Domine";
            font-size: 16px;
            width: 100%;
            border-radius: 0px;
            padding: 15px 0px 8px 0px;
            border: 0px;
            border-bottom: 1px solid #707070;
            transition: 0.3s ease-in-out;
            &:focus{
                outline: none;
                border-bottom: 1px solid #F28E1D;

                .test{
                    color: red;
                }
            }

            @media screen and (max-width: 1200px){
                text-align: left;
            }
        }
        .ckbox{
            display: flex;
            font-family: "Poppins";
            font-weight: 400;
            line-height: 1.4;
            margin: 20px 0px;
            
            input{
                height: 15px;
                width: 15px;
                margin-right: 15px;

                @media screen and(max-width: 765px){
                    height: 60px;
                    width: 60px;
                }
            }
        }
        input[type=submit]{
            border: 1px solid black;
            padding: 0;
            height: 50px;
            background: #fff;
            text-align: center;
            color: black;
            transition: 0.3s ease-in-out;
            &:hover{
                background: black;
                color: #fff;
                cursor: pointer;
            }
        }
        textarea{
            font-family: "Domine";
            font-size: 16px;
            width: 100%;
            padding: 15px 0px 5px 0px;
            border: 0px;
            border-bottom: 1px solid #707070;
            min-height: 100px;
            @media screen and (max-width: 1200px){
                text-align: left;
            }

            &:focus{
                outline: none;
                border-bottom: 1px solid #F28E1D;
            }
        }
    }
`