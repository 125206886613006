import { AnimatePresence, motion } from 'framer-motion'
import React from 'react'
import { GrClose } from "react-icons/gr";
import { useStateContext } from '../context/StateContext';
const Popup = () => {
    const { menu, setMenu } = useStateContext();


    const handleDownload = () => {
        const link = document.createElement('a');
        link.href = require('../slony-karmel-przepis.pdf');
        link.setAttribute('download', 'slony-karmel-przepis.pdf');
        document.body.appendChild(link);
        link.click();
        link.remove();
    }
    return (
        <AnimatePresence>
            <motion.div className='recepit-container' style={{
                display: menu ? 'block' : 'none',
            }}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: .35 }}
                key="modal"
            >
                <div className='receipt-place'>

                    <div className='receipt-close-icon' style={{ cursor: "pointer" }} onClick={() => setMenu(false)}>
                        <GrClose />
                    </div>
                    <div className='receipt-flex'>
                        <div className='receipt-content'>
                            <h2>Sprawdź nową, oryginalną recepture
                                dla lodów "Słony Karmel" według firmy: <br />
                                <a href="https://www.receptury.net/" target='_blank'>receptury.net</a>
                            </h2>
                            <p>
                                Odkryj smakowitą innowację w lodach! "Słony karmel 2.0" to nieodparty hit dla miłośników lodów o intensywnym smaku. Nasza nowa receptura przynosi wyjątkowy twist tradycyjnego smaku karmelu, który pokochasz.
                            </p>
                            <button className='download-link' onClick={handleDownload} ><u>Pobierz recepturę</u></button>
                        </div>
                        <div className='recepit-images'>
                            <div className='image1' style={{
                                backgroundImage: `url(${require('../images/receptura-slony-karmel-2-przod.png')})`
                            }}></div>
                            <div className='image2' style={{
                                backgroundImage: `url(${require('../images/receptura-slony-karmel-2-tyl.png')})`
                            }}></div>
                        </div>
                    </div>
                </div>
            </motion.div>
        </AnimatePresence>
    )
}

export default Popup