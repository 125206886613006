import React from 'react'

import HeaderComponent from '../../components/Header'
import ListContentComponenet from '../../components/ListConent'
import Subtitle from '../../components/Subtitle'
import { Content, CukiernieAboutContainer, CukiernieAboutWrapper, ImageDulce, Image } from './CukiernieAboutSection'
import ImagePath from '../../images/cukiernie-image.jpg'


const CukiernieAboutSection = ({imgSrc}) => {
    return (
        <CukiernieAboutContainer>
            <CukiernieAboutWrapper>

           

                <Content>
      

                    <Subtitle
                        text="Najważniejsze cechy naszego produktu:"
                        textDark="true"
                        textBold="true"
                    />

                    <ListContentComponenet
                        text="Produkt doskonale nadaje się do przekładania wafli, babeczek, ciast i tortów, jako nadzienie do szprycowania."
                        textBold=""
                        textDark="true"
                        svgWhite=""
                    />


                    <ListContentComponenet
                        text="Produkt jest stabilny termicznie."
                        textBold=""
                        textDark="true"
                        svgWhite=""
                    />

                    <ListContentComponenet
                        text="Nie zamarza, schłodzony lekko gęstnieje utrzymując połysk."
                        textBold=""
                        textDark="true"
                        svgWhite=""
                    />

                    <ListContentComponenet
                        text="Nie krystalizuje się."
                        textBold=""
                        textDark="true"
                        svgWhite=""
                    />

                    <ListContentComponenet
                        text="Dostępne są dwie odmiany dla cukierników, różniące się konsystencją."
                        textBold=""
                        textDark="true"
                        svgWhite=""
                    />

                    <ListContentComponenet
                        text="Temp. przechowywania do 10 stopni C."
                        textBold=""
                        textDark="true"
                        svgWhite=""
                    />

                    <ListContentComponenet
                        text="Bez GMO i sztucznych konserwantów."
                        textBold=""
                        textDark="true"
                        svgWhite=""
                    />
                </Content>

                <Image>
                    <ImageDulce imgSrc={ImagePath}/>
                </Image>

            </CukiernieAboutWrapper>
        </CukiernieAboutContainer>

    )
}

export default CukiernieAboutSection