import React from 'react'
import SingleProduct from './singleProduct'

import { Container, Wrapper, GridContainer, TableContainer } from './Elements'
import { Subtitle } from '../../components/Subtitle/SubtitleElements'
import HeaderComponent from '../../components/Header'
import Popup from '../../components/Popup'

const LodziarnieProducts = () => {




    const products = [
        {
            "id": 1,
            "title": "Topping",
            "desc": `Do variegowania, polewania i dekorowania lodów, deserów lodowych i kuwet lodowych.`,
            "add": [
                "Aromat naturalny waniliowy",
            ],
            "size": "Puszka 3,0 kg z wieczkiem",
            "date": "18 miesięcy",
            "asc": "według uznania",
            "imgSrc": require('../../images/top.png'),

        },

        {
            "id": 2,
            "title": "Variegato",
            "desc": `Do variegowania, polewania i dekorowania lodów, deserów lodowych i kuwet lodowych.`,
            "add": [
                "Zagęszczacz naturalny agar 0 agar",
            ],
            "size": "Puszka 3,0 kg z wieczkiem",
            "date": "18 miesięcy",
            "asc": "według uznania",
            "imgSrc": require('../../images/var.png'),
        },

        {
            "id": 3,
            "title": "Pasta",
            "desc": `Do produkcji oryginalnych lodów o smaku „Dulce” oraz „Mleczny karmel”.`,
            "add": [
                "Palony cukier",
            ],
            "size": "Puszka 3,0 kg z wieczkiem",
            "date": "18 miesięcy",
            "asc": "od 50g do 180g na 1kg mieszanki bazowej",
            "imgSrc": require('../../images/pas.png'),
        },

        {
            "id": 4,
            "title": "Concentrato",
            "desc": `Nowy produkt w ofercie Huso z linii lodowej Dulce de leche. Do produkcji oryginalnych lodów o smaku „Dulce” – „Mleczny karmel” oraz wielu innych rodzajów lodów. `,
            "add": [
                "Palony cukier", "Syrop glukozowy", "Aromat naturalny",
            ],
            "size": "Puszka 0,98 kg z wieczkiem",
            "date": "18 miesięcy",
            "asc": "od 5g do 25g na 1kg mieszanki bazowej",
            "imgSrc": require('../../images/conc.png'),
        },

    ]

    const tableData = [
        {
            "tableId": "Concentrato",
            "gramatura": "0,98kg",
            "dataWaznosci": "18 miesięcy",
            "IloscSztukNaWarstwie": "72",
            "IloscWarstNaPalecie": "9",
            "IloscSztukNaPalecie": "648 / 635 kg"
        },
        {
            "tableId": "Pasta",
            "gramatura": "3 kg",
            "dataWaznosci": "18 miesięcy",
            "IloscSztukNaWarstwie": "36",
            "IloscWarstNaPalecie": "7",
            "IloscSztukNaPalecie": "252 / 756 kg"
        },
        {
            "tableId": "Variegato",
            "gramatura": "3 kg",
            "dataWaznosci": "18 miesięcy",
            "IloscSztukNaWarstwie": "36",
            "IloscWarstNaPalecie": "7",
            "IloscSztukNaPalecie": "252 / 756 kg"
        },
        {
            "tableId": "Topping",
            "gramatura": "3 kg",
            "dataWaznosci": "18 miesięcy",
            "IloscSztukNaWarstwie": "36",
            "IloscWarstNaPalecie": "7",
            "IloscSztukNaPalecie": "252 / 756 kg"
        },
    ]



    return (
        <>

            <Container>
                <Wrapper>

                    <GridContainer>
                        {

                            products.map((item, index) => (
                                <SingleProduct key={index} id={item.id} item={item} />
                            ))

                        }

                    </GridContainer>

                    

                    <TableContainer>
                        <table>
                            <tr>
                                <td className='table-header'></td>
                                <td className='table-header'>Gramatura</td>
                                <td className='table-header'>Data ważności</td>
                                <td className='table-header'>Ilośc sztuk na warstwie</td>
                                <td className='table-header'>Ilość warstw na palecie</td>
                                <td className='table-header'>Ilość sztuk/ilość warst na palecie</td>
                            </tr>
                            {
                                tableData.map((singleItem, index) => (
                                    <tr key={index} className='hover'>
                                        <td className='table-orange'>{singleItem.tableId}</td>
                                        <td className='table-info'>{singleItem.gramatura}</td>
                                        <td className='table-info'>{singleItem.dataWaznosci}</td>
                                        <td className='table-info'>{singleItem.IloscSztukNaWarstwie}</td>
                                        <td className='table-info'>{singleItem.IloscWarstNaPalecie}</td>
                                        <td className='table-info'>{singleItem.IloscSztukNaPalecie}</td>
                                    </tr>

                                ))
                            }


                        </table>

                    </TableContainer>


                </Wrapper>
            </Container>

        </>
    )
}

export default LodziarnieProducts