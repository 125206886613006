import React from 'react'


import { MainContainer, MainWrapper} from './Elements';
import SingleItem from './singleItem';


const categories = [
  {
    "id": 1,
    "title": "Pastelero",
    "desc": `Gęste, doskonałe do nadziewania przed 
      wypiekiem, do zastosowania na zewnątrz ciast, 
      utrzymuje połysk i nadany kształt`,
    imageUrl : require('../../images/pastelero.png'),
  },
  {
    "id": 2,
    "title": "Raffole",
    "desc": `Mniej gęste, doskonałe do 
      szprycowania ciast po wypieku`,
    imageUrl : require('../../images/raffole.png'),
  }
];


const CukierniePasteleroRaffoleSection = () => {

  return (
    <>
      <MainContainer>
        <MainWrapper>
          {
            categories.map((item) => (
              <SingleItem
                id={item.id}
                item={item}
              />
            ))
          }

        </MainWrapper>
      </MainContainer>
    </>
  )
}

export default CukierniePasteleroRaffoleSection