import styled from 'styled-components'


export const ListConentContainer = styled.div`
    
`

export const ListContentWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 20px;

    & > *{
        margin: 0;
        padding: 0;
    }

`

export const Col1 = styled.div`

`
export const CircleSVG = styled.img`
    padding-bottom: 2px;
`
export const Col2 = styled.div`

`

export const Col2P = styled.p`
    font-family: 'Poppins';
    font-weight: ${({textBold}) => (textBold ? '900' : '400')};
    color: ${({textDark}) => (textDark ? '#2D2D2D' : '#fff')};

    @media screen and (max-width: 950px){
        max-width: 600px;
    }
`