import React from 'react'
import { ButtonSideBarContainer, ButtonWrapper } from './ButtonElements'



export const ButtonKontakt = ({ text }) => {

    return (
        <ButtonWrapper to='/kontakt'>
            {text}
        </ButtonWrapper>
    )
}


export const ButtonStronaGlowna = ({ text }) => {
    return (
        <ButtonWrapper to='/'>
            {text}
        </ButtonWrapper>
    )
}



export const ButtonKontaktSideBar = ({ text }) => {
    return (
        <ButtonSideBarContainer to='/kontakt'>
            {text}
        </ButtonSideBarContainer>
    )
}
