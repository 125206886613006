import styled from 'styled-components';
import { Link as LinkR } from 'react-router-dom';


export const FooterContainer = styled.div`
    background: #161616;
    padding: 0 24px;
    /* margin-top: 120px; */

`

export const FooterWrapper = styled.div`
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    padding: 50px 0px;

    justify-content: space-between;

    @media screen and (max-width: 768px){
        flex-direction: column;

        & > *{
            width: 100%;
            text-align: center;
        }
    }

`

export const FooterHeader = styled.h4`
    color: #6E6E6E;
    font-family: "Domine";
    font-size: 1rem;
`
export const FooterMenu = styled.div`

@media screen and (max-width: 768px){
        /* padding-top: 40px; */
    }

`

export const FooterParagraph = styled.p`
    color: #fff;
    font-family: "Domine";
    font-size: 1rem;
`
export const FooterButtonContact = styled(LinkR)`
    font-family: 'Poppins';
    padding: 10px 30px;

    border: 1px solid #fff;
    font-size: 12px;
    text-decoration: none;
    color: #fff;
    transition: 0.3s ease-in-out;

    &:hover{
        background: rgba(0,0,0,0.8);
    }
`

export const FooterMenuLinks = styled(LinkR)`
    color: #6E6E6E;
    transition: 0.3s ease-in-out;
    text-decoration: none;
    
    
    &:hover{
        color: #fff;
        transition: 0.3s ease-in-out;
    }
    `
export const Column1 = styled.div`

`
export const Column2 = styled.div`

`
export const Column3 = styled.div`

`
export const Column4 = styled.div`
@media screen and (max-width: 768px){
        padding-top: 40px;
    }
`
export const FooterAuthorContainer = styled.div`
    max-width: 1200px;
    margin: 0 auto;
    `

export const FooterAuthorWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center; 
    font-size: 12px;
    font-family: 'Domine';
    color: #fff;
    padding-bottom: 24px;


    @media screen and (max-width: 768px){
        flex-direction: column;
        text-align: center;
    }
    `

export const FooterParagraphAuthorLink = styled.a`
    font-family: 'Domine';
    text-decoration: none;
    color: #fff;
    transition: 0.3s ease-in-out;
    color: #6E6E6E;

    &:hover{
        color: #fff;
    }
`

export const FooterParagraphAuthor = styled.p`
    
`


