import React from 'react'
import HeaderComponent from '../../components/Header';
import ListContentComponenet from '../../components/ListConent';
import { Container, Wrapper, TableSectionMobile, Description, Addition, InfoSection, Data, DataInfo, ImageSection, Image } from './singleProductsElements';
import { Button } from '../../components/HeroSection/HeroSectionElements';
import { useStateContext } from '../../context/StateContext';

const SingleProduct = ({ item }) => {

    const { title, desc, add, size, date, asc, imgSrc, tableId, gramatura, dataWaznosci, IloscSztukNaWarstwie, IloscWarstNaPalecie, IloscSztukNaPalecie } = item;

    const { menu, setMenu } = useStateContext();


    return (
        <>
            <Container>
                <Wrapper>


                    <HeaderComponent
                        text={title}
                        textBold="true"
                        textDark="true" />

                    <Description>
                        {desc}
                    </Description>

                    <Addition>
                        <p>Dodatkowo do podstawy surowcowej dodano:</p>

                        {
                            add.map((singleAddition, index) => (
                                <ListContentComponenet
                                    key={index}
                                    text={singleAddition}
                                    textBold=""
                                    textDark="true"
                                    svgWhite=""
                                />
                            ))
                        }

                    </Addition>

                    <InfoSection>
                        <Data>
                            Opakowanie:
                        </Data>
                        <DataInfo>
                            {size}
                        </DataInfo>

                        <Data>
                            Termin ważności:
                        </Data>
                        <DataInfo>
                            {date}
                        </DataInfo>

                        <Data>
                            Dozowanie:
                        </Data>
                        <DataInfo>
                            {asc}
                        </DataInfo>


                    </InfoSection>

                    <ImageSection>
                        <Image src={imgSrc} alt="" />

                    </ImageSection>
                    {
                        title === 'Pasta' && (
                            <div className='receipt-button' onClick={() => setMenu(true)}>
                                <a>Sprawdź nowy przepis!</a>
                            </div>
                        )
                    }


                    <TableSectionMobile>
                        <table>

                            <tr>
                                <td className='header'>Gramatura</td>
                                <td className='info'>{gramatura}</td>
                            </tr>
                            <tr>
                                <td className='header'>Data Ważności</td>
                                <td className='info'>{dataWaznosci}</td>
                            </tr>
                            <tr>
                                <td className='header'>Ilość sztuk na warstwie</td>
                                <td className='info'>{IloscSztukNaWarstwie}</td>
                            </tr>
                            <tr>
                                <td className='header'>Ilość warstw na palecie</td>
                                <td className='info'>{IloscWarstNaPalecie}</td>
                            </tr>
                            <tr>
                                <td className='header'>Ilość sztuk/Ilość wartstw na palecie</td>
                                <td className='info'>{IloscSztukNaPalecie}</td>
                            </tr>
                        </table>
                    </TableSectionMobile>

                </Wrapper>
            </Container>

        </>
    )
}

export default SingleProduct