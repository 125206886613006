import styled from 'styled-components'


import HeroImage from '../../images/hero-section-image.png'
import { Link as LinR } from 'react-router-dom'


export const HeroSectionContainer = styled.div`
    background-image: url(${HeroImage});
    height: 55em;
    position: relative;

    @media screen and (max-width: 550px){
        height: 40em;
    }

    *{
        margin: 0;
    }
`
export const HeroSectionWrapper = styled.div`
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    height: 100%;
    flex-direction: column;
    position: relative;
    padding: 0 24px;

    @media screen and (max-width: 950px){
        justify-content: center;
        align-items: center;
    }

`
export const HeroDark = styled.div`
    height: 100%;
    background: radial-gradient(circle, rgba(0,0,0,0.3) 0%, rgba(0,0,0,0.7) 74%, rgba(0,0,0,0.75) 100%);
`
export const Section1 = styled.div`
    display: flex;
    align-items: center;
    padding-bottom: 30px;
    width: 100%;

    @media screen and (max-width: 768px){
        justify-content: center;
    }
    
`
export const HeroSectionAbsolute = styled.div`
    position: absolute;
    top: 40%;
    transform: translateY(-40%);

    @media screen and (max-width: 950px){
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    }

`
export const Col1 = styled.div`

`
export const LineSVG = styled.img`
    @media screen and (max-width: 768px){
    width: 30px;
}
`

export const Col2 = styled.div`
    padding: 0px 30px;

    @media screen and (max-width: 350px){
        padding: 0px 12px;
}
`
export const Logos = styled.img`
    width: 400px;

    @media screen and (max-width: 768px){
    width: 220px;
}

@media screen and (max-width: 360px){
    width: 170px;
}
`

export const Col3 = styled.div`

`
export const Section2 = styled.div`
  @media screen and (max-width: 950px){
        text-align: center;
    }


`
export const Heading = styled.h1`
    font-family: 'Domine';
    font-weight: 900;
    color: #fff;
    font-size: 3rem;
    line-height: 1.9;
    width: 70%;
    padding-bottom: 50px;

    @media screen and (max-width: 950px){
        font-size: 2rem;
        text-align: center;
        width: 100%;
    }

    @media screen and (max-width: 550px){
        font-size: 1.5rem;
    }

`
export const Button = styled(LinR)`
    font-family: 'Poppins';
    color: #fff;
    text-decoration: none;
    border: 1px solid #fff;
    padding: 15px 30px;
    font-size: 1rem;
    transition: 0.3s ease-in-out;

    @media screen and (max-width: 768px){
        padding: 20px 15px;
        font-size: 0.875rem;
    }


    &:hover{
        background: white;
        color: black;
    }
`
