import React from 'react'
import { Col1, Col2, Col3, HeaderContainer, HeaderWrapper, LineSVG } from './HeaderElements';


const HeaderComponent = ({text, textDark, textBold}) => {
  return (
    <>
        <HeaderContainer>
            <HeaderWrapper>
                <Col1 >
                    <LineSVG src= {require('../../images/line.svg').default}/>
                </Col1>

                <Col2 textDark={textDark} textBold={textBold}>{text}</Col2>

                <Col3>
                    <LineSVG src= {require('../../images/line.svg').default}/>
                </Col3>

            </HeaderWrapper>
        </HeaderContainer>
    </>
  )
}

export default HeaderComponent