import styled from 'styled-components';
import { Link as LinkR } from 'react-router-dom';


export const Container = styled.div`
    margin: 100px 0px;
`

export const Wrapper = styled.div`
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 24px;
    @media screen and (max-width: 815px){
        padding: 0 12px;
    }
`

export const Flex = styled.div`
    display: grid;
    align-items: center;
    grid-template-areas: 'col1 col2';


    @media screen and (max-width: 815px){
        grid-template-areas:  'col2' 'col1';
        text-align: center;
    }

`
export const ProductImage = styled.img`
    width: 35vh;
    top: 50%;
    right: 10%;
    transform: translate(10%, -50%);
    position: absolute;

    @media screen and (max-width: 815px){
        position: relative;
        transform: translate(0);
        top: 0;
        right: 0;
        width: 300px;
    }

    @media screen and (max-width: 400px){
        width: 200px;
    }
`

export const AdditionFlex = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    line-height: 1.2;
    padding: 20px 0px 50px 0px;
    column-gap: 20px; 

    
    @media screen and (max-width: 815px){
        display: flex;
        flex-direction: column;
        line-height: 1.2;
        padding: 00px 0px 50px 0px;
        align-items: center;
        column-gap: 0;


    }


`

export const TableRowCustom = styled.tr`
    display: ${({visibility}) => (visibility ? " " : "none")}
`

export const HeaderCenter = styled.div`
    @media screen and (max-width: 815px){
        display: flex;
        justify-content: center;
    }
`

export const Column2 = styled.div`
    width: 40%;
    position: relative;
    grid-area: col2;


    @media screen and (max-width: 815px){
       width: 100%;
    }

`

export const Column1 = styled.div`
    grid-area: col1;
    @media screen and (max-width: 815px){
        width: 100%;
    }
    
    
    width: 60%;

    .addition{
        font-size: 17px;
        font-family: "domine";
        font-weight: bolder;
        color: #2D2D2D;
    }


    .info{
        font-size: 13px;
        font-family: "Domine";
        color: #979797;
        width: 100%;
        line-height: 1.7;
        padding: 30px 0px;

        @media screen and (max-width: 815px){
        width: 100%;
        margin-bottom: 50px;
    }
    }

    table{
        font-family: "Domine";
        font-size: 17px;
        border-collapse: collapse;
        width: 100%;

        @media screen and (max-width: 400px){
        font-size: 14px;
    }


        .table-row{
            border-top: 1px solid #CECECE;
            transition: 0.1s ease-in-out;

            &:hover{
                background: #E5E5E5;
            }
        }

        tr{
            line-height: 2.5;
                    
        }
        .float-left{
            text-align: left;
            padding-left: 10px;
        }
        .float-right{
            text-align: right;
            padding-right: 10px;
        }

        .header{
            color: #F28E1D;
            font-weight: bolder;
        }
        tr:nth-child(2){
            background: #F3F3F3;

        }
        tr:nth-child(4){
            background: #F3F3F3;
        }
        tr:nth-child(6){
            background: #F3F3F3;
        }
        tr:nth-child(8){
            background: #F3F3F3;
        }
    }

`

export const Btn = styled(LinkR)`
    text-decoration: none;
    color: #322E31;
    border: 1px solid #322E31;
    padding: 20px 250px;
    font-family: "Poppins";
    transition: 0.3s ease-in-out;

    &:hover{
        background: black;
        color: #fff;
    }
    @media screen and (max-width: 815px){
        padding: 20px 100px;
    }
`

export const HowToUseContent = styled.div`
    display: ${({ howtouse }) => howtouse ? "block" : "none"};
    p:nth-child(1){
        font-family: "Domine";
        font-weight: bolder;
    }
    p:nth-child(2){
        font-family: "Poppins";
        line-height: 1.7;
    }

`
export const HowToHoldContainer = styled.div`
    display: ${({ howtohold }) => howtohold ? "block" : "none"};
    padding: 30px 0px;
    p:nth-child(1){
        font-family: "Domine";
        font-weight: bolder;
    }
    p:nth-child(2){
        font-family: "Poppins";
        line-height: 1.7;
    }
`

export const Fat = styled.div`
display: ${({ fat }) => fat ? "block" : "none"};
    p{
        font-family: 'Poppins';
    }
`

export const Subtitle = styled.div`
display: ${({ subtitle }) => subtitle ? "block" : "none"};
    p{
        font-family: 'Poppins';
    }
`