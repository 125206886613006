import React from 'react'
import HeaderComponent from '../../components/Header'
import Subtitle from '../../components/Subtitle'
import Paragraph from '../../components/Paragraph'
import { Column1, SubtitleHelper, ParagraphA, Column2, CustomersOpinionContainer, CustomersOpinionWrapper, EndingContainer, EndingWrapper, OpinionsContainer } from './CustomersOpinionElemenets'


const CustomersOpinionSection = () => {
    return (
        <>
            <CustomersOpinionContainer>
                <CustomersOpinionWrapper>


                    <HeaderComponent
                        text="Opinie klientów"
                        textDark="true"
                        textBold="true"
                    />
                    <SubtitleHelper>
                        <Subtitle
                            text="Każda współpraca jakiej się podejmujemy jest dla nas najważniejsza. Dbamy o zadowolenie naszych klientów, aby mogli nam zaufać na wiele lat."
                            textBold="true"
                            textDark="true"
                        />

                    </SubtitleHelper>
                    <OpinionsContainer>
                        <Column1>
                            <Paragraph
                                text="Wieloletnia, wspaniała współpraca!"
                                textBold="true"
                                textDark="true"
                            />
                            <Paragraph
                                text="Firma dulce de leche to profesjonalna i solidna firma, która zapewnia wysokiej jakości produkty i szybkie terminy dostaw. Ich obsługa klienta jest również na bardzo wysokim poziomie, zawsze służą pomocą i są gotowi odpowiedzieć na wszystkie pytania i wątpliwości. Ogólnie jestem bardzo zadowolony z ich usług i polecam tę firmę innym."
                                textBold=""
                                textDark="true"
                            />
                        </Column1>

                        <Column2>
                            <Paragraph
                                text="Niezawodność i rzetelność"
                                textBold="true"
                                textDark="true"
                            />
                            <Paragraph
                                text="Dulce de leche to rzetelny partner, który zawsze dostarcza produkt w wysokiej jakości. Ich dulcejest pyszne i cieszy się dużą popularnością wśród moich znajomych. Co więcej, obsługa klienta jest bardzo miła i pomocna, a dostawy są zawsze punktualne. Jestem bardzo zadowolony z współpracy z tą firmą i z czystym sumieniem mogę polecić ją innym."
                                textBold=""
                                textDark="true"
                            />

                        </Column2>

                    </OpinionsContainer>
                    
                    <EndingContainer>
                            <EndingWrapper>
                                <Subtitle
                                    text="Dziękujemy za zaufanie"
                                    textBold=""
                                    textDark="true"
                                />
                                <ParagraphA>
                                    dulcedeleche.pl
                                </ParagraphA>
                            </EndingWrapper>

                        </EndingContainer>


                </CustomersOpinionWrapper>
            </CustomersOpinionContainer>
        </>
    )
}

export default CustomersOpinionSection