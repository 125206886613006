import styled from 'styled-components';

export const InfoBoxContainer = styled.div`
    

    position: absolute;
    top: 20%;
    left: 20px;
    transform: translateY(-40%);
    background: #fff;
    font-family: "Poppins";
    border-radius: 17px;
    box-shadow: 0px 3px 10px 0px rgba(191, 191, 191, 1);

    @media screen and (max-width: 1200px){
        display: block;
        position: relative;
        top: 15%;
        left: 50%;
        transform: translate(-50%, -15%) ;
        border-radius: 0;
    }


`
export const InfoBoxContent = styled.div`

    padding: 10px 30px 20px 20px;

    @media screen and (max-width: 1200px){
        padding: 10px 4px 20px 4px;
    }

    p{
        font-size: 15px;
    }
    .first-line{
        font-weight: bolder;
        color: black;
    }
    .second-line{
        color: grey;
        
    }
    a{
        color: #F28E1D;
    }
    
`
