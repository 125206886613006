import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet-async'
import HeaderComponent from '../components/Header'
import ListContentComponenet from '../components/ListConent'
import { Flex, HeaderCenter, TableRowCustom, Fat, Subtitle, HowToHoldContainer, HowToUseContent, AdditionFlex, Container, Btn, ProductImage, Wrapper, Column1, Column2 } from '../sections/DetalProducts/dulceSingleProductPageElements'


const DulceSingleProductPage = ({
  imgSrc, title, addition, energia, tłuszcz, wtymkwasytłuszczowenasycone,
  węglowodany, wtymcukry, białko, sól, wapń, subtitle, fat, sposobUzycia,
  warunkiPrzechowywania,
}) => {



  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }, [])


  return (
    <>
      <Helmet>
        <title>{title} - dulce de leche</title>
        <meta name='description' content="Informacje o produktach" />
      </Helmet>

      <Container>
        <Wrapper>
          <Flex>
            <Column1>
              <HeaderCenter>


                <HeaderComponent
                  text={title}
                  textBold="true"
                  textDark="true" />


              </HeaderCenter>


              <Subtitle subtitle={subtitle ? 1 : 0}>
                <p>{subtitle}</p>
              </Subtitle>


              <Fat fat={fat ? 1 : 0}>
                <p>{fat}</p>
              </Fat>


              <AdditionFlex>

                {
                  addition.map((element) =>
                    <ListContentComponenet text={element}
                      textBold=""
                      textDark="true"
                      svgWhite="" />
                  )
                }
              </AdditionFlex>


              <HowToUseContent howtouse={sposobUzycia ? 1 : 0}>
                <p>Sposób użycia: </p>
                <p>{sposobUzycia}</p>
              </HowToUseContent>

              <HowToHoldContainer howtohold={warunkiPrzechowywania ? 1 : 0}>
                <p>Warunki Przechowywania</p>
                <p>{warunkiPrzechowywania}</p>
              </HowToHoldContainer>

              <table>
                <tr>
                  <td className='header float-left'>Średnia wartość odżywcza</td>
                  <td className='header float-right'>w 100g produktu</td>
                </tr>
                <tr className='table-row'>
                  <td className='float-left'>Energia</td>
                  <td className='float-right'>{energia}</td>
                </tr>
                <tr className='table-row'>
                  <td className='float-left'>Tłuszcz</td>
                  <td className='float-right'>{tłuszcz}</td>
                </tr>
                <tr className='table-row'>
                  <td className='float-left'>W tym kwasy tłuszczowe nasycone</td>
                  <td className='float-right'>{wtymkwasytłuszczowenasycone}</td>
                </tr>
                <tr className='table-row'>
                  <td className='float-left'>Węglowodany</td>
                  <td className='float-right'>{węglowodany}</td>
                </tr>
                <tr className='table-row'>
                  <td className='float-left'>w tym cukry</td>
                  <td className='float-right'>{wtymcukry}</td>
                </tr>
                <tr className='table-row'>
                  <td className='float-left'>Białko</td>
                  <td className='float-right'>{białko}</td>
                </tr>
                <tr className='table-row'>
                  <td className='float-left'>Sól</td>
                  <td className='float-right'>{sól}</td>
                </tr>
                <TableRowCustom visibility={wapń ? 1 : 0} className='table-row'>
                  <td className='float-left'>Wapń</td>
                  <td className='float-right'>{wapń}</td>
                </TableRowCustom>

              </table>

              <p className='info'>
                * % dziennych referencyjnych wartości spożycia (dla osób dorosłych). Najlepiej spożyć przed: data na nakrętce. Po otwarciu przechowywać w lodówce.
              </p>
              <Btn to="/detal" >Powrót</Btn>
            </Column1>
            <Column2>
              <ProductImage src={imgSrc} />
            </Column2>

          </Flex>
        </Wrapper>
      </Container>
    </>

  )
}

export default DulceSingleProductPage