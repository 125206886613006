import React from 'react'
import HeaderComponent from '../../components/Header'
import { HeroSectionContainer, HeroSectionWrapper, HeroSubtitle, HeroSectionDark, ParagraphHelper } from './HeroElements'
import Subtitle from '../../components/Subtitle'


const HeroSection = ({ id, headerText, title, subtitle, imgSrc }) => {
    return (
        <>
            <HeroSectionContainer imgSrc={imgSrc} id={id}>
                <HeroSectionDark>
                    <HeroSectionWrapper >

                        <HeaderComponent
                            text={headerText}
                            textDark=""
                            textBold="true"
                        />

                        <Subtitle
                            text={title}
                            textBold="true"
                            textDark=""
                        />
                        <ParagraphHelper>

                        <HeroSubtitle>{subtitle}</HeroSubtitle>

                        </ParagraphHelper>


                    </HeroSectionWrapper>
                </HeroSectionDark>
            </HeroSectionContainer>
        </>
    )
}

export default HeroSection