import CukierniePage from "./pages/CukierniePage";
import DetalPage from "./pages/DetalPage";
import LodziarniePage from "./pages/LodziarniePage";
import PrzemyslPage from "./pages/PrzemyslPage";
import StronaGlowna from "./pages/StronaGlowna";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import NavBar from "./components/NavBar";
import KontaktPage from "./pages/KontaktPage";

import DulceSingleProductPage from "./pages/DulceSingleProduct";
import { DulceCzekolada, DulceKlasyczne, DulceSolMorska, KajmakKakaowy, KajmakKlasyczny, MlekoZageszczone } from "./Data";
import Popup from "./components/Popup";
import { AnimatePresence, motion } from "framer-motion";
import { useStateContext } from "./context/StateContext";



function App() {

  const { menu } = useStateContext();

  return (
    <>
      {menu &&
        <Popup />
      }
      <Router>
        <Routes>
          <Route path='/' element={<NavBar />}>
            <Route index element={<StronaGlowna />}></Route>
            {/* <Route path="/przemysl" element={<PrzemyslPage />}></Route> */}
            <Route path="/lodziarnie" element={<LodziarniePage />}></Route>
            <Route path="/detal" element={<DetalPage />}></Route>
            <Route path='/detal/dulce-de-leche-klasyczne' element={<DulceSingleProductPage {...DulceKlasyczne} />} />
            <Route path='/detal/dulce-de-leche-sol-morska' element={<DulceSingleProductPage {...DulceSolMorska} />} />
            <Route path='/detal/dulce-de-leche-czekolada' element={<DulceSingleProductPage {...DulceCzekolada} />} />
            <Route path='/detal/kajmak-klasyczny' element={<DulceSingleProductPage {...KajmakKlasyczny} />} />
            <Route path='/detal/kajmak-kakaowy' element={<DulceSingleProductPage {...KajmakKakaowy} />} />
            <Route path='/detal/mleko-zageszczone-slodzone' element={<DulceSingleProductPage {...MlekoZageszczone} />} />
            <Route path="/cukiernie" element={<CukierniePage />}></Route>
            <Route path="/kontakt" element={<KontaktPage />}></Route>
          </Route>
        </Routes>
      </Router>
    </>
  );
}

export default App;
