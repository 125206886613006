import React from 'react'
import { CircleSVG, Col1, Col2, Col2P, ListConentContainer, ListContentWrapper } from './ListContentElements';


const ListContentComponenet = ({ text, textBold, textDark, svgWhite }) => {

    return (
        <>
            <ListConentContainer>
                <ListContentWrapper>
                    <Col1>
                        <CircleSVG src={svgWhite ? require('../../images/white-circle.svg').default : require('../../images/black-circle.svg').default} />
                    </Col1>
                    <Col2>
                        <Col2P textBold={textBold} textDark={textDark}>
                            {text}
                        </Col2P>

                    </Col2>
                </ListContentWrapper>

            </ListConentContainer>

        </>
    )
}

export default ListContentComponenet