import React, { useState } from 'react'

import { MobileIcon, Nav, Container, NavBarBtn, NavBarContainer, NavBarItem, NavBarLogo, NavBarMenu, NavLink } from './NavBarElements'
import { FaBars } from 'react-icons/fa'

import { Outlet } from 'react-router-dom'
import { ButtonKontakt } from '../Button'

import SideBarComponent from '../SideBar'



const NavBar = () => {


    const [isOpen, setIsOpen] = useState(false);

    const toggleSideBar = () => {
        setIsOpen(!isOpen);
    }

    return (
        <Container>
            <Nav>
                <NavBarContainer>

                    <NavBarLogo to="/">
                        Dulce de Leche
                    </NavBarLogo>

                    <MobileIcon onClick={toggleSideBar}>
                        <FaBars />
                    </MobileIcon>

                    <NavBarMenu>



                        <NavBarItem>
                            <NavLink to='/cukiernie'>
                                Cukiernie
                            </NavLink>
                        </NavBarItem>


                        <NavBarItem>
                            <NavLink to='/lodziarnie'>
                                Lodziarnie
                            </NavLink>
                        </NavBarItem>


                        <NavBarItem>
                            <NavLink to='/detal'>
                                Detal
                            </NavLink>
                        </NavBarItem>

                        <NavBarItem>
                            <NavBarBtn>
                                <ButtonKontakt text='Skontaktuj się' />
                            </NavBarBtn>

                        </NavBarItem>



                    </NavBarMenu>



                </NavBarContainer>
            </Nav>
            <SideBarComponent isOpen={isOpen} toggleSideBar={toggleSideBar} />

            <Outlet />
        </Container>
    )
}

export default NavBar