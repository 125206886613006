import React from 'react'
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';
import CustomPin from '../../images/icon.svg';
import { InfoBoxContainer, InfoBoxContent } from './Elements';

const containerStyle = {
    width: "100%",
    height: "100%",
};

const center = {
    lat: 52.2373896,
    lng: 21.0597424,
};

const options = {
    mapId: "afb9cd8fc888a89e",
}


function MapComponent() {
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: "AIzaSyDnXRXWx073iB1WLFLWLaQhTRz6SCdIn8I"
    })


    return isLoaded ? (
        <GoogleMap
            mapContainerStyle={containerStyle}
            center={center}
            zoom={13}
            options={options}
        >
            <InfoBoxContainer>
                <InfoBoxContent>

                    <p className='first-line'>Niekłańska 35</p>
                    <p className='second-line'>Niekłańska 35, 03-924 Warszawa</p>
                        <a href='https://www.google.pl/maps/place/Niek%C5%82a%C5%84ska+35,+03-924+Warszawa/@52.2373896,21.0575537,17z/data=!3m1!4b1!4m5!3m4!1s0x471ecdae5d016c8f:0x64dca11ffb4b4c89!8m2!3d52.2373896!4d21.0597424' target="_blank" rel="noreferrer" >
                            Otwórz w Google Maps
                        </a>
                </InfoBoxContent>

            </InfoBoxContainer>

            <Marker
                position={center}
                icon={CustomPin}
                onClick={() => { console.log('icon clicked') }}
                title={"title"}
            />

        </GoogleMap>
    ) : <></>
}

// const Marker = (options) => {
//     const [marker, setMarker] = React.useState();

//     React.useEffect(() => {
//       if (!marker) {
//         setMarker(new google.maps.Marker());
//       }

//       // remove marker from map on unmount
//       return () => {
//         if (marker) {
//           marker.setMap(null);
//         }
//       };
//     }, [marker]);
//     React.useEffect(() => {
//       if (marker) {
//         marker.setOptions(options);
//       }
//     }, [marker, options]);
//     return null;
//   };

export default React.memo(MapComponent)