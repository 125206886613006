import React from 'react'
import { Paragraph } from './ParagraphElements'


const ParagraphComponent = ({textBold, text, textDark}) => {
  return (
    <>
        <Paragraph textDark = {textDark} textBold = {textBold}>
            {text}
        </Paragraph>
    </>
  )
}

export default ParagraphComponent