import styled from 'styled-components'

export const Container = styled.div`
    padding: 50px 0px;

`
export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;

    @media screen and (max-width: 950px){
        align-items: center;
    }

`

export const Description = styled.p`
    font-family: 'Poppins';
    color: #2D2D2D;
    font-weight: 500;
    line-height: 1.5;
    width: 85%;

    @media screen and (max-width: 950px){
        text-align: center;
    }

    `

export const Addition = styled.div`
    font-family: "Domine";
    font-weight: bolder;
    padding-top: 25px;
    font-size: 1.075rem;
    color: #2D2D2D;
    height: 200px;

    @media screen and (max-width: 950px){
        height: auto;
    }




    p {
        
        margin: 0px 0px 15px 0px;

        @media screen and (max-width: 550px){
        text-align: center;
        line-height: 1.5;
  
    }
    }

    div {
        padding: 5px 0px;
        
        @media screen and (max-width: 950px){
        display: flex;
        justify-content: center;
        font-size: 16px;
        padding: 3px 0px;
    }

        & > *{
            margin: 0;
        }
    }


`


export const InfoSection = styled.div`


@media screen and (max-width: 950px){
        text-align: center;
        padding-top: 30px;
    }

`

export const Data = styled.p`
    font-family: "Domine";
    font-weight: bolder;
    font-size: 1.075rem;
    color: #2D2D2D;
`
export const DataInfo = styled.p`
    font-family: 'Poppins';
    color: #7A7A7A;
    font-weight: 500;

`

export const ImageSection = styled.div`
`

export const Image = styled.img`
    height: 400px;
    padding-top: 40px;

    @media screen and (max-width: 550px){
        height: 250px;
        padding-top: 20px;
    }

`

export const TableSectionMobile = styled.div`
    display: none;
    width: 100%;
    


    @media screen and (max-width: 768px){
        

        display: flex;
        justify-content: center;
        align-items: center;

        font-family: 'Domine';
        font-size: 16px;
        line-height: 1.5;
        text-align: left;

        table{
            width: 100%;
        }

        td{
            padding: 15px 10px;
            height: 100%;
        }

        tr:nth-child(1){
            background: #F3F3F3;
        }
        tr:nth-child(3){
            background: #F3F3F3;
        }
        tr:nth-child(5){
            background: #F3F3F3;
        }
        .header{
            color: #AEAEAE;
            float: left;
        }
        .info{
            color: #322E31;
            float: right;
            font-weight: bolder; 

        }
    }

    @media screen and (max-width: 500px){

        font-size: 14px;
        

        td{
            padding: 5px 5px;
        }

        .header{
            width: 90px;
        }
    }
    

`