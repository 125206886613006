import React, { useEffect } from 'react'
import HeroSection from '../components/HeroSection'
import AboutCompanySection from '../sections/AboutCompany';
import OurProductsSection from '../sections/OurProducts';
import CustomersOpinionSection from '../sections/CustomersOpinion'
import Footer from '../components/Footer'
import { Helmet } from 'react-helmet-async';

const StronaGlowna = () => {

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }, [])

  return (
    <>
    {/* //test */}
      <Helmet>
        <title>Dulce de leche - strona główna</title>
        <meta name='description' content="Polski dystrybutor przysmaku dulce de leche." />
      </Helmet>
      <HeroSection />
      <AboutCompanySection />
      <OurProductsSection />
      <CustomersOpinionSection />
      <Footer />
    </>
  )
}

export default StronaGlowna