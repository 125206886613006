






export const DulceKlasyczne = {
    "imgSrc": require('./images/klasyczny.png'),
    "title": "Dulce De Leche Klasyczne",
    "addition": [
        "mleko świeże (61%)",
        "odtłuszczone mleko w proszku 0% tłuszczu (8,4%)",
        "cukier",
    ],
    "energia": "1218 kj/ 288kcal",
    "tłuszcz": "3,5 g",
    "wtymkwasytłuszczowenasycone": "1.9 g",
    "węglowodany": "57 g",
    "wtymcukry": "56 g",
    "białko": "7,4 g",
    "sól": "0,35 g",
    "wapń": "0,29 g (36%)"
};

export const DulceSolMorska = {
    "imgSrc": require('./images/sol-morska.png'),
    "title": "Dulce De Leche Z Solą Morską",
    "addition": [
        "mleko świeże (61%)",
        "cukier",
        "odtłuszczone mleko w proszku 0% tłuszczu (8,4%)",
        "sól morska (0,8% na 100g produktu)"
    ],
    "energia": "1218 kJ/288 kcal",
    "tłuszcz": "3,5 g",
    "wtymkwasytłuszczowenasycone": "1.9 g",
    "węglowodany": "57 g",
    "wtymcukry": "56 g",
    "białko": "7,4 g",
    "sól": "1 g",
    "wapń": "0,29 g (36%)"
};

export const DulceCzekolada = {
    "imgSrc": require('./images/czekoladowy.png'),
    "title": "Dulce De Leche Z Czekoladą",
    "addition": [
        "mleko świeże (61%)",
        "cukier",
        "odtłuszczone mleko w proszku 0% tłuszczu (8,4%)",
        "czekolada (miazga kakaowa, lecytyna sojowa, wanilia) (5% na 100g produktu)"
    ],
    "energia": "1246 kJ/296 kcal",
    "tłuszcz": "4,6 g",
    "wtymkwasytłuszczowe nasycone": "2,7 g",
    "węglowodany": "56 g",
    "wtymcukry": "56 g",
    "białko": "7,3 g",
    "sól": "0,33 g",
    "wapń": "0,27g (34%)"
};

export const KajmakKlasyczny = {
    "imgSrc": require('./images/kajmak-klasyczny.jpg'),
    "title": "Kajmak - Klasyczny",
    "subtitle": "Gotowane mleko skondensowane, słodzone.",
    "addition": [
        "mleko 48%",
        "cukier",
    ],
    "fat": "Sucha masa bezłuszczowa min.18%, tłuszcz min. 6%.",
    "sposobUzycia": `
        Stosowane do bezpośredniego spożycia lub jako gotowa
        masa do różnego rodzaju deserów: ciast, wafli, naleśników lub pieczywa.
        Najlepiej spożyć przed: data na wieczku
        Termin przydatności do spożycia jest jednocześnie numerem partii.
    `,
    "warunkiPrzechowywania": `
        Przechowywać w temperaturze do +24 °C. Po otwarciu przechowywać 
        w temperaturze od +2 °C do +8 °C i spożyć w przeciągu 3 dni. 
        Podczas przechowywania może wykrystalizować się cukier mlekowy 
        (laktoza). Nie zamrażać!
    `,
    "masaNetto": "397 g",
    "energia": "1363Kj/ 323 kcal",
    "tłuszcz": "6 g",
    "wtymkwasytłuszczowenasycone": "4 g",
    "węglowodany": "60 g",
    "wtymcukry": "60 g",
    "białko": "5,5 g",
    "sól": "0,17 g",
};


export const KajmakKakaowy = {
    "imgSrc": require('./images/kajmak-kakaowy.jpg'),
    "title": "Kajkmak - Kakaowy",
    "subtitle": "Gotowane mleko skondensowane, słodzone.",
    "addition": [
        "mleko 48%",
        "cukier",
        "kakao w proszku o obniżonej zawartości tłuszczu (4%)",

    ],
    "fat": "Sucha masa beztłuszczowa min. 12%, tłuszcz min. 6%.",
    "sposobUzycia": `
        Stosowane do bezpośredniego spożycia lub jako gotowa
        masa do różnego rodzaju deserów: ciast, wafli, naleśników lub pieczywa.
        Najlepiej spożyć przed: data na wieczku
        Termin przydatności do spożycia jest jednocześnie numerem partii.
    `,
    "warunkiPrzechowywania": `
        Przechowywać w temperaturze do +24 °C. Po otwarciu przechowywać w 
        temperaturze od +2 °C do +8 °C i spożyć w przeciągu 3 dni. Podczas 
        przechowywania może wykrystalizować się cukier mlekowy (laktoza). 
        Nie zamrażać!
    `,
    "masaNetto": "397 g",
    "energia": "1134 kJ/ 269 kcal",
    "tłuszcz": "6 g",
    "wtymkwasytłuszczowenasycone": "4 g",
    "węglowodany": "49 g",
    "wtymcukry": "48 g",
    "białko": "4,1 g",
    "sól": "0,18 g",
};

export const MlekoZageszczone = {
    "imgSrc": require('./images/mleko-zageszczone.jpg'),
    "title": "Mleko Zagęszczone Słodzone",
    "subtitle": "Mleko skondensowane, słodzone",
    "addition": [
        "mleko 48%",
        "cukier",

    ],
    "fat": `Mleko zagęszczone słodzone zawiera 8% tłuszczu
            i 20% suchej masy beztłuszczowej mleka.`,
    "sposobUzycia": `
        stosowane do bezpośredniego spożycia lub jako dodatek
        do deserów, owoców, ciast, wafli, naleśników, kawy, herbaty.
        Najlepiej spożyć przed (Data minimalnej trwałości jest jednocześnie
        numerem partii): data na spodzie puszki (na wieczku).
    `,
    "warunkiPrzechowywania": `
        Przechowywać w temperaturze od +1 do +24˚C. Po otwarciu przechowywać w temperaturze od +2 do +8˚C
        i spożyć w przeciągu 3 dni. Podczas przechowywania może wystąpić
        krystalizacja cukru. Nie zamrażać!
    `,
    "masaNetto": "1000 g",
    "energia": "1134 kJ/ 269 kcal",
    "tłuszcz": "6 g",
    "wtymkwasytłuszczowenasycone": "4 g",
    "węglowodany": "49 g",
    "wtymcukry": "48 g",
    "białko": "4,1 g",
    "sól": "0,18 g",
};