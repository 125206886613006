import React from 'react'
import HeaderComponent from '../../components/Header'
import DulceSingleProduct from './dulceSingleProduct'
import { Container, Wrapper, Gap, GridOneColumn, GridThreeColumns, GridTwoColumns } from './Elemenets'
import { Route, Routes } from 'react-router-dom';
import KontaktPage from '../../pages/KontaktPage';
import {Link as LinkR} from 'react-router-dom';


const DetalProducts = () => {
    const DulceArray = [
        {
            "id": "1",
            "imgSrc": require('../../images/klasyczny.png'),
            "logoImgSrc": require('../../images/klasyczny-logo.png'),
            "buttonLink": '/detal/dulce-de-leche-klasyczne'
        },
        {
            "id": "2",
            "imgSrc": require('../../images/sol-morska.png'),
            "logoImgSrc": require('../../images/sol-morska-logo.png'),
            "buttonLink": '/detal/dulce-de-leche-sol-morska'
        },
        {
            "id": "3",
            "imgSrc": require('../../images/czekoladowy.png'),
            "logoImgSrc": require('../../images/czekolada-logo.png'),
            "buttonLink": '/detal/dulce-de-leche-czekolada'
        }
    ]

    const KajmakArray = [
        {
            "id": 4,
            "imgSrc": require('../../images/kajmak-klasyczny.jpg'),
            "logoImgSrc": require('../../images/logo-kajmak-klasyczny.png'),
            "buttonLink": '/detal/kajmak-klasyczny'
        },

        {
            "id": 5,
            "imgSrc": require('../../images/kajmak-kakaowy.jpg'),
            "logoImgSrc": require('../../images/logo-kajmak-kakaowy.png'),
            "buttonLink": '/detal/kajmak-kakaowy'
        },


    ]

    const MlekoArray = [
        {
            "id": 6,
            "imgSrc": require('../../images/mleko-zageszczone.jpg'),
            "logoImgSrc": require('../../images/logo-mleko.png'),
            "buttonLink": '/detal/mleko-zageszczone-slodzone'
        },


    ]



    return (
        <Container>
            <Wrapper>
                <Gap>

                    <HeaderComponent
                        text="Dulce De Leche"
                        textBold="true"
                        textDark="true"
                    />
                </Gap>

                <GridThreeColumns>

                    {
                        DulceArray.map((singleProduct) =>
                            <DulceSingleProduct {...singleProduct} />
                        )
                    }

                </GridThreeColumns>

                <Gap>
                    <HeaderComponent className="gap"
                        text="Kajmak"
                        textBold="true"
                        textDark="true"
                    />
                </Gap>
                <GridTwoColumns>

                    {
                        KajmakArray.map((singleProduct) =>
                            <DulceSingleProduct {...singleProduct} />
                        )
                    }

                </GridTwoColumns>
                <Gap>
                    <HeaderComponent className="gap"
                        text="Mleko Zagęszczone"
                        textBold="true"
                        textDark="true"
                    />
                </Gap>

                <GridOneColumn>

                    {
                        MlekoArray.map((singleProduct) =>
                            <DulceSingleProduct {...singleProduct} />
                        )
                    }

                </GridOneColumn>

                <Gap className='last-title'>
                    <p className='auchan-title'>Znajdź nasze produkty w punktach Auchan.</p>
                    <p className='auchan-subtitle'>Staramy się zwiększać ilość w sklepów, w których będą Państwo w stanie odnaleźć nasze produkty. Na dzień dzisiejszy jesteśmy dumni informując, że Dulce de leche jak i sam kajmak mogą Państwo znaleźć w punktach Auchan zlokalizowanych w wielu miejscach w Polsce.</p>
                    <a href="https://www.google.pl/maps/search/Auchan/" target="_blank">Sprawdź nabliższy sklep</a>
                </Gap>

            </Wrapper>
        </Container>
    )
}

export default DetalProducts