import React from 'react'
import HeaderComponent from '../../components/Header'
import ParagraphComponent from '../../components/Paragraph'
import SubtitleComponent from '../../components/Subtitle'
import { OurProductContainer, Dark, OurProductContent, OutProductWrapper, SubtitleHelper } from './OurProductsElements';
import ListContentComponenet from '../../components/ListConent'



const OurProductsSection = () => {
  return (
    <>
      <OurProductContainer>
        <Dark>
          <OutProductWrapper>
            <OurProductContent>

              <HeaderComponent
                text="Nasze Produkty"
                textDark=""
                textBold="s"
              />
              <SubtitleHelper>

                <SubtitleComponent
                  text="Wysoka jakość naszych produktów wynika z krótkiej listy składników"
                  textBold="true"
                  textDark=""
                />
              </SubtitleHelper>

              <ParagraphComponent
                text="Podstawę surowcową stanowią trzy składniki: "
                textBold="bold"
                textDark=""
              />

              <ListContentComponenet
                text="pełnotłuste świeże mleko"
                textBold=""
                textDark=""
                svgWhite="true"
              />
              <ListContentComponenet
                text="odtłuszczone mleko w proszku"
                textBold=""
                textDark=""
                svgWhite="true"
              />
              <ListContentComponenet
                text="cukier"
                textBold=""
                textDark=""
                svgWhite="true"
              />


            </OurProductContent>
          </OutProductWrapper>
        </Dark>
      </OurProductContainer>
    </>
  )
}

export default OurProductsSection