export const cukierniePageData = {
    id: 'cukiernie',
    headerText: "Cukiernie",
    title: 'Dulce de Leche',
    subtitle: 'Kajmak argentyński, dedykowany do wytwórców ceniących, jakość i szukających kajmaku bez tłuszczów roślinnych.',
    imgSrc: require('../../images/hero-section-image.png'),
}


export const lodziarniePageData = {
    id: 'lodziarnie',
    headerText: "Lodziarnie",
    title: `Przedstawiamy w 100% naturalne pasty/wariegatury do produkcji lodów.     Bazę surowcową stanowi świeże mleko, mleko w proszku i cukier`,
    subtitle: `Wszystkie zostały stworzone ze starannie wyselekcjonowanych, naturalnych składników. Bazy te pozwalają na wyprodukowanie lodów o niesamowitym oraz niepowtarzalnym smaku.
    Wyróżnia nas brak jakichkolwiek konserwantów, barwników czy polepszaczy. 
    `,
    imgSrc: require('../../images/dulce-image-4.png'),
}


export const detalPageData = {
    id: 'detal',
    headerText: "Detal",
    title: 'Kajmak, rozumiany jako specjał wytwarzany z cukru i słodkiej śmietanki na wzór kajmaku tureckiego.',
    subtitle: `Pojawił się na polskich stołach szlacheckich w XVIII wieku, za panowania Stanisława Augusta. Cukier był wtedy towarem luksusowym, upodobanie polskiej szlachty do słodyczy rozwinęło się pod wpływem kontaktów z kuchniami Wschodu. 
    `,
    imgSrc: require('../../images/dulce-image-5.png'),
}
