import styled from 'styled-components';

export const Subtitle = styled.h2`
font-family: 'Domine';
font-size: 2rem;
line-height: 1.7;
color: ${({textDark}) => (textDark ? '#2D2D2D' : '#fff')};
font-weight: ${({textBold}) => (textBold ? 'bolder' : 'lighter')};

@media screen and (max-width: 768px){
   font-size: 1.5rem;
 }
`
