import styled from 'styled-components'

export const Container = styled.div`

`


export const Wrapper = styled.div`
       height: 1000px;

    
    @media screen and (max-width: 1200px){
        height: auto;
        padding: 0 12px;
        margin-top: 75px;
    }
`

export const Flex = styled.div`
    display: flex;
    align-items: center;

    @media screen and (max-width: 1200px){
        flex-direction: column;
        justify-content: center;
        text-align: center;
    }
`

export const Component = styled.div`

        justify-content: center;
        align-items: center;
        display: flex;
        
    @media screen and (max-width: 1200px){
        
        margin-top: 50px;
    }
`

export const Column1 = styled.div`

        
@media screen and (max-width: 1200px){
        width: 100%;
        max-width: 700px;
    }

    width: 50%;
    margin: 0 auto;
    max-width: 500px;

    
`

export const Column2 = styled.div`
    width: 50%;
    @media screen and (max-width: 1200px){
        width: 100%;
            }
`

export const MapContainer = styled.div`
    height: 1000px;
    @media screen and (max-width: 1200px){
        height: 600px;
        margin: 50px 0px;
            }
`
